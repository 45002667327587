import React, { Component } from 'react'
import Layout from '../components/Layout'
import OurCompanyNavigationRow from '../components/OurCompanyNavigationRow'

export default class Terms extends Component {
    render() {
        return (
            <Layout
                title="Terms of Use | HomeSpotter"
                description="Read about our stated terms of use at homespotter.com"
            >
                <OurCompanyNavigationRow location={this.props.location} />
                <div className="ourcompany terms">
                    <h1>TERMS OF USE</h1>
                    <h2>RULES OF ENGAGEMENT</h2>
                    <p>
                        HomeSpotter, LLC has developed certain web capabilities
                        and related mobile phone applications that provide users
                        with real estate information and/or tools pertaining to
                        real estate. This HomeSpotter Terms of Use (the "Terms")
                        covers all of HomeSpotter, LLC websites and mobile phone
                        applications, which include, but are not limited to, the
                        website(s) at https://www.homespotter.com/,
                        https://www.spacio.com, the HomeSpotter and Spacio
                        applications for iPhone, iPad, and Android and
                        specifically includes any branded website(s) or
                        applications we have developed on behalf of our
                        partners, such as multiple listing services ("MLSs"),
                        associations, brokers, agents, or other enterprises
                        (collectively the "HS Sites"). The HS Sites are owned
                        and operated by HomeSpotter, LLC (referred to as "HS",
                        "we," "us" or "our"). PLEASE READ THESE TERMS CAREFULLY
                        BEFORE USING THE HS SITES. By using the HS Sites you
                        agree to these Terms. If you do not agree to the Terms,
                        please do not use the HS Sites. We reserve the right, at
                        our discretion, to change, modify, add or remove
                        portions of the Terms at any time. Please check these
                        Terms periodically for changes. Your continued use of
                        the HS Sites following the posting of changes to the
                        Terms will mean you accept those changes.
                    </p>

                    <h2>YOUR SUBMISSIONS</h2>
                    <p>
                        One of the goals of the HS Sites is to create an
                        environment that encourages users to post and share
                        their comments, flags, likes, links, photos, saved
                        searches, and recommendations with other users of the HS
                        Sites. The HS Sites may include one or more areas where
                        you can post your comments, flags, likes, links, photos,
                        saved searches, and recommendations. All comments,
                        flags, likes, links, photos, saved searches,
                        recommendations, data, text, software, music, sound,
                        graphics, video, messages, or any other materials
                        whatsoever, whether publicly posted or privately
                        transmitted to HS or the HS Sites, shall be collectively
                        referred to as the "Content." The submitting user
                        retains ownership of Content. Notwithstanding your
                        ownership of the Content, to the extent that you have
                        submitted Content to HS or posted Content to the HS
                        Sites, you grant HS the royalty-free, perpetual,
                        irrevocable, world-wide, non-exclusive, transferable
                        license to use, reproduce, modify, publish, translate,
                        distribute, and display such Content (in whole or part)
                        on the HS Sites. HS has not, and will not, review,
                        monitor or edit the Content for accuracy, timeliness,
                        integrity or completeness. HS shall have the right (but
                        not the obligation) in its sole discretion to refuse or
                        delete any Content that it considers to violate the
                        Terms or be otherwise illegal. HS, in its sole and
                        absolute discretion, may preserve Content and may also
                        disclose Content if required to do so by law, judicial
                        or governmental mandate or, to protect the rights,
                        property, or personal safety of HS Sites users and the
                        public.
                    </p>
                    <p>
                        The HS Sites may include one or more areas for uploading
                        contact lists containing information such as email
                        address, phone number, address, or other personal
                        contact information that the submitting user may have
                        collected. By uploading and storing contact information
                        on the HS Sites you assert that this information is
                        first party data which you have collected directly and
                        have the right to use for marketing purposes.
                    </p>

                    <h2>PRINCIPLES OF PARTICIPATION</h2>
                    <p>
                        In order to maximize the user experience at the HS Sites
                        we ask you to follow these rules: (a) do not upload to,
                        distribute through or otherwise publish through the HS
                        Sites any Content that is libelous, defamatory, obscene,
                        pornographic, threatening, invasive of privacy or
                        publicity rights, abusive, illegal or otherwise
                        objectionable that would constitute or encourage a
                        criminal offense, violate the rights of any party or
                        that would otherwise give rise to liability or violate
                        any law; (b) do not use the HS Sites in any manner that
                        could be offensive to other users, including but not
                        limited to posting Content that promotes racism,
                        bigotry, hatred or physical harm of any kind against any
                        group or individual, or that could be harmful to minors
                        or that harasses or advocates harassment of another
                        person; (c) only use the HS Sites in a manner consistent
                        with all laws and regulations and in accordance with
                        these Terms; (d) do not impersonate any person or
                        entity, misrepresent any affiliation with another
                        person, entity, or association, use false IP addresses
                        or headers, or otherwise conceal your identity for any
                        purpose; (e) only submit Content for which you have the
                        copyright or other specific permission to distribute
                        electronically; (f) do not violate, plagiarize, or
                        infringe on the rights of third parties including
                        copyright, trademark, trade secret, privacy, publicity
                        or proprietary rights; and (g) do not, without our
                        express written approval, distribute or otherwise
                        publish any Content containing any solicitation of
                        funds, advertising, or solicitation for the sale of
                        goods or services, affiliate marketing offers, link
                        referral codes, junk mail, "spam," chain letters or
                        pyramid schemes.
                    </p>

                    <h2>THE HS SITES</h2>
                    <p>
                        The HS Sites, including but not limited to all text,
                        graphics, logos, icons, images, data, graphs, audio,
                        videos, computer programs and other material and
                        information contained on, or utilized in the provision
                        of, the HS Sites are the property of HS or its content
                        suppliers and are protected by copyrights, trademarks,
                        trade secrets, patents or other proprietary rights. HS
                        hereby grants you a limited, nonexclusive,
                        non-transferable personal license to use the HS Sites
                        for personal or informational purposes only. You may
                        print and/or download and store portions of HS Sites for
                        non-commercial or personal use only, provided that you
                        retain all copyright and other proprietary notices
                        contained on the HS Sites. Except as expressly
                        authorized by HS in writing, you may not use, copy,
                        distribute, modify or create derivative works from,
                        disclose, display, transmit, or post or any portion of
                        the HS Sites for any purpose, create internet "links" to
                        the HS Sites or frame" or "mirror" the HS Sites on any
                        other server or wireless or Internet-based device. All
                        rights not expressly granted herein are reserved to HS
                        and/or its licensors.
                    </p>

                    <p>
                        In addition to the restrictions set forth above, you may
                        not use any "page-scrape", "robot", "spider" or other
                        automatic device, program, algorithm or methodology, or
                        any similar or equivalent manual process, to access,
                        acquire, copy or monitor any portion of the HS Sites or
                        any Content, or in any way reproduce or circumvent the
                        navigational structure or presentation of the HS Sites
                        or any Content, to obtain or attempt to obtain any
                        materials, documents or information through any means
                        not purposely made available through the HS Sites. HS
                        reserves the right to bar any such activity.
                    </p>

                    <p>
                        To access and use all of the features available on the
                        HS Sites you must register as a user. Each HS Sites user
                        must: (a) personally provide true, accurate, current and
                        complete information on the HS Sites' registration form
                        (collectively, the "Registration Data") and (b) maintain
                        and promptly update the Registration Data as necessary
                        to keep it true, accurate, current and complete. If,
                        after investigation, HS has reasonable grounds to
                        suspect that any user's information is untrue,
                        inaccurate, not current or incomplete, HS may suspend or
                        terminate any and all current or future use of the HS
                        Sites by that user.
                    </p>

                    <p>
                        A user may create and/or receive passwords and account
                        designations upon completing certain HS Sites
                        registration processes and is wholly responsible for
                        maintaining the confidentiality of such passwords or
                        designations.
                    </p>

                    <p>
                        A user may create and/or receive passwords and account
                        designations upon completing certain HS Sites
                        registration processes and is wholly responsible for
                        maintaining the confidentiality of such passwords or
                        designations.
                    </p>

                    <h2>CHILDREN</h2>
                    <p>
                        The HS Sites are not intended for use by children. If
                        you are under 13, you may not submit or post any
                        information or material on the HS Sites or otherwise
                        provide such information to HS, including but not
                        limited to personally identifiable information.
                    </p>

                    <h2>TERMINATION</h2>
                    <p>
                        HS may terminate a user's ability to use the HS Sites in
                        HS's absolute discretion and for any reason. HS may
                        terminate your use of the HS Sites for reasons that
                        include, but are not limited to, the following: (1)
                        violation of these Terms; (2) abuse of HS Sites
                        resources or attempt to gain unauthorized entry to the
                        HS Sites; or (3) as required by law, regulation, court
                        or governing agency order. HS's termination of any
                        user's access to the HS Sites may be effected without
                        notice and, on such termination, HS may immediately bar
                        any further access to the HS Sites. HS shall not be
                        liable to any user or other third party for any
                        termination of that user's access to the HS Sites. In
                        the event of termination, HS reserves the right to
                        delete, or not delete a user's Content at HS's sole
                        discretion.
                    </p>

                    <h2>LINKS</h2>
                    <p>
                        Each user shall indemnify, defend and hold harmless HS
                        from any and all claims, demands, damages, costs, and
                        liabilities including reasonable attorneys' fees, made
                        by any third party due to or arising out of that user's
                        acts or omissions, including claims arising out of that
                        user's use of the HS Sites; his or her submission,
                        posting or transmission of Content or his or her
                        violation of the Terms.
                    </p>

                    <p>
                        HS does not control the Content posted to the HS Sites
                        and, as such, does not guarantee the accuracy, integrity
                        or quality of such Content. Under no circumstances will
                        HS be liable in any way for any Content, including, but
                        not limited to, liability for any errors or omissions in
                        any Content or for any loss or damage of any kind
                        incurred as a result of the use of any Content posted,
                        emailed or otherwise transmitted or displayed via the HS
                        Sites. Each user, by using the HS Sites, may be exposed
                        to Content that is offensive, indecent or objectionable.
                        Each user must evaluate, and bear all risks associated
                        with the use of the HS Sites and any Content, including
                        any reliance on the accuracy, completeness, timeliness,
                        integrity or usefulness of the HS Sites or any such
                        Content.
                    </p>

                    <p>
                        THE HS SITES WILL PROVIDE YOU WITH ACCESS TO VARIOUS
                        REAL ESTATE INFORMATION MADE AVAILABLE BY THIRD PARTIES,
                        NONE OF WHICH ARE AFFILIATED WITH HS. YOUR DECISION TO
                        TAKE ADVANTAGE OF ANY SUCH INFORMATION IS SOLELY AT YOUR
                        OWN RISK. THE THIRD PARTY PROVIDING SUCH INFORMATION,
                        AND NOT HS, IS SOLELY RESPONSIBLE FOR THE PRODUCTS AND
                        SERVICES PROVIDED THROUGH SUCH INFORMATION. HS HAS NOT
                        INDEPENDENTLY REVIEWED OR VERIFIED THE INFORMATION OF
                        SUCH THIRD PARTIES.
                    </p>

                    <p>
                        EACH USER'S USE OF THE HS SITES ARE AT HIS OR HER SOLE
                        RISK. THE HS SITES ARE PROVIDED ON AN "AS IS" AND "AS
                        AVAILABLE" BASIS AND HS ASSUMES NO RESPONSIBILITY FOR
                        THE TIMELINESS, DELETION, MIS-DELIVERY OR FAILURE TO
                        STORE ANY CONTENT OR COMMUNICATIONS. HS EXPRESSLY
                        DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                        IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                        PURPOSE AND NON-INFRINGEMENT. HS SHALL NOT BE LIABLE FOR
                        ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL
                        OR EXEMPLARY DAMAGES RESULTING FROM ANY USER'S USE OR
                        INABILITY TO USE THE HS SITES. SOME JURISDICTIONS DO NOT
                        ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE
                        LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
                        CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE
                        LIMITATIONS MAY NOT APPLY TO YOU.
                    </p>

                    <h2>TRADEMARK INFORMATION</h2>
                    <p>
                        "HomeSpotter" is a trademark of HS. All other marks,
                        names, and logos mentioned on the HS site are the
                        property of their respective owners. Your use of the HS
                        trademarks and other marks, names and logos set forth on
                        the HS Sites without prior written consent is strictly
                        prohibited.
                    </p>

                    <h2>COPYRIGHTS</h2>
                    <p>
                        HS respects the intellectual property rights of others,
                        and requires that the people who use the HS Sites do the
                        same. It is our policy to respond promptly to claims of
                        intellectual property misuse. If you believe that your
                        work has been copied and is accessible on the HS Sites
                        in a way that constitutes copyright infringement, you
                        may notify us by providing our copyright agent with the
                        following information in writing: (1) the electronic or
                        physical signature of the owner of the copyright or the
                        person authorized to act on the owner's behalf; (2)
                        identification of the copyrighted work that you claim
                        has been infringed; (3) identification of the material
                        that is claimed to be infringing and information
                        reasonably sufficient to permit HS to locate the
                        material, including the full URL. (4) your name,
                        address, telephone number, and email address. (5) a
                        statement by you that you have a good faith belief that
                        the disputed use is not authorized by the copyright
                        owner, its agent, or the law; (6) a statement, made
                        under penalty of perjury, that the above information in
                        your notice is accurate and that you are the copyright
                        owner or are authorized to act on the copyright owner's
                        behalf.
                    </p>

                    <p>
                        Our designated agent to receive notification of claimed
                        infringement under the Digital Millennium Copyright Act
                        of 1998 is:
                    </p>

                    <p>
                        John Roberts
                        <br />
                        New Counsel, PLC
                        <br />
                        233 Park Avenue
                        <br />
                        Minneapolis, Minnesota
                        <br />
                        612-659-8443
                        <br />
                        jroberts@newcounsel.com
                        <br />
                    </p>

                    <h2>PRIVACY</h2>
                    <p>
                        HS agrees to treat your private personally identifiable
                        information in accordance with the terms of our then
                        current privacy policy, which is incorporated herein for
                        all purposes, and which is available for review at
                        https://homespotter.com/privacy or by sending an
                        e-mail request to: privacy@homespotter.com.
                    </p>

                    <h2>GENERAL INFORMATION</h2>
                    <p>
                        These Terms constitute the entire agreement between each
                        user and HS and govern each user's use of HS Sites,
                        superseding any prior agreements. These Terms and the
                        relationship between each user and HS shall be governed
                        by the laws of the State of Minnesota without regard to
                        its conflict of law provisions and each party shall
                        submit to the personal and exclusive jurisdiction of the
                        courts located within the county of Hennepin, Minnesota.
                        The HS Sites are controlled and operated by HS from its
                        offices within the State of Minnesota, United States of
                        America. HS makes no representation that materials in
                        the site are appropriate or available for use in other
                        locations. Those who choose to access this site from
                        other locations do so on their own initiative and are
                        responsible for compliance with local laws, if and to
                        the extent local laws are applicable. If any provision
                        of the Terms is found by a court of competent
                        jurisdiction to be invalid, the parties nevertheless
                        agree that the court should endeavor to give effect to
                        the parties' intentions as reflected in the provision,
                        and the other provisions of the Terms remain in full
                        force and effect. Nothing herein shall be deemed to
                        create an agency, partnership, joint venture,
                        employee-employer or franchisor-franchisee relationship
                        of any kind between HS and any user.
                    </p>
                </div>
            </Layout>
        )
    }
}
